import {
  ComponentProps,
  createEffect,
  createSignal,
  JSX,
  onCleanup,
  untrack,
} from "solid-js";

declare namespace Expand {
  type Props = Omit<ComponentProps<"div">, "style"> & {
    class?: string;
    style?: JSX.CSSProperties;
    direction: "x" | "y" | "xy";
    show?: unknown;
    transition?: JSX.CSSProperties["transition"];
    children: JSX.Element;
  };
}

function Expand({
  direction,
  style,
  children,
  show = true,
  transition,
  ...props
}: D<Expand.Props>) {
  const [dimensions, setDimensions] = createSignal<{
    width?: number;
    height?: number;
  } | null>({
    width: direction.includes("x") ? 0 : undefined,
    height: direction.includes("y") ? 0 : undefined,
  });

  let ref: HTMLDivElement = null!;

  createEffect(() => {
    const dir = untrack(() => direction);
    const x = dir.includes("x");
    const y = dir.includes("y");
    const { scrollWidth, scrollHeight } = ref.firstChild as HTMLElement;
    const width = x ? scrollWidth : undefined;
    const height = y ? scrollHeight : undefined;

    if (show) {
      setDimensions({ width, height });
    } else {
      setDimensions({ width, height });
      const raf = requestAnimationFrame(() => {
        setDimensions({
          width: x ? 0 : undefined,
          height: y ? 0 : undefined,
        });
      });
      onCleanup(() => {
        cancelAnimationFrame(raf);
      });
    }
  });

  return (
    <div
      ref={ref}
      {...props}
      style={{
        transition: transition || "var(--normal-s)",
        overflow: dimensions() == null ? undefined : "hidden",
        width: `${dimensions()?.width}px`,
        height: `${dimensions()?.height}px`,
        ...style,
      }}
      onTransitionEnd={() => {
        // if (show()) setDimensions(null);
      }}
    >
      {children}
    </div>
  );
}

export default Expand;
